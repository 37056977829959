import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export interface getDeviceList_returns_data_item {
  dev_id: string;
  location: string;
  lat: string;
  long: string;
  aqi: string;
  district?: string | null;
  location_id: number;
  id: number;
  stncode: string;
  last_online: string;
}

export interface getDeviceList_returns_data {
  connected_to: string;
  status: string;
  data: getDeviceList_returns_data_item[];
}

export const getDeviceList = async (): Promise<
  AxiosResponse<getDeviceList_returns_data>
> => {
  const config: AxiosRequestConfig = {
    url: "http://mutant1.distronix.in:1100/v1.0/sens/get_device_list_int?location_id=7",
    method: "POST",
  };
  const response: AxiosResponse<getDeviceList_returns_data> = await axios(
    config
  );
  return response;
};
