import { UserInputCredentials, UserState } from "../../pages/user/UserSlice";


export const MockSignIn = ({
  userId,
  password,
}: UserInputCredentials): Promise<Required<Omit<UserState, "state">>> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const result = validUserPasswordCombinations.find(
        (i) => i.userId === userId
      );
      if (typeof result === "undefined")
        reject("User Id does not exist. Please check or contact admin.");
      else if (result.password === password)
        resolve({
          userId: result.userId,
          username: result.username,
          token: result.token,
          logInTime: new Date().toDateString(),
        });
      else reject("Password does not match. Please contact admin.");
    }, 1000);
  });
};

export interface IMockValidUserPasswordCombinations
  extends UserInputCredentials {
  username: string;
  token: string;
}

export const validUserPasswordCombinations: IMockValidUserPasswordCombinations[] = [
  {
    userId: "admin@wbpcb.gov.in",
    username: "WBPCB Administrater",
    password: "Paribesh@2021",
    token: "83ujdsjb2ie0qad';scKJDHFvLDNVR(FIEFflel;/ewfef",
  },
  {
    userId: "admin@distronix.in",
    username: "Distronix Administrater",
    password: "123456",
    token: "83ujdsjb2ie0qad';scKJDHFvLDNVR(FIEFflel;/ewfef",
  },
  {
    userId: "admin@questmall.in",
    username: "Administrater",
    password: "Quest@123",
    token: "83ujdsjb2ie0qad';scKJDHFvLDNVR(FIEFflel;/ewfef",
  },
];
